<template>
  <div>
    <NavBar />
    <div class="dialogWrapper">
      <v-overlay absolute :value="loading">
        <v-progress-circular indeterminate color="#137547"></v-progress-circular>
      </v-overlay>
      <div class="dialogWindow">
        <h2>Prijavi se!</h2>
        <v-form ref="form" lazy-validation>
          <v-text-field
            @keydown.space.prevent
            v-model="login.email"
            color="#137547"
            outlined
            label="Email"
            placeholder="Unesite Vaš email"
            type="text"
            :rules="emailRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="login.password"
            color="#137547"
            outlined
            label="Lozinka"
            placeholder="Unesite lozinku"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
            style="margin-bottom"
            :rules="passwordRules"
            required
          ></v-text-field>
          <p>{{ errorMessage }}</p>
          <p class="forgottenPassword" @click="() => this.$router.push('/zaboravljena-lozinka')">Zaboravili ste lozinku?</p>
          <button class="logInButton" @click.prevent="submitForm">Prijavi se</button>
        </v-form>
        <h3 class="noAccountQuestion">Još uvek nemaš nalog?</h3>
        <button class="registerButton" @click.prevent="redirectToSignUp">Registruj se</button>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NavBar from "../Components/NavBar.vue";
import Footer from "../Components/Footer.vue";

export default {
  components: {
    NavBar,
    Footer,
  },
  name: "SignInPage",
  data() {
    return {
      loading: false,
      login: {
        email: "",
        password: "",
      },
      show2: false,
      errorMessage: "",
      emailRules: [
        (v) => !!v || "Email je obavezan",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||
          "Unesite email u ispravnom formatu",
      ],
      passwordRules: [(v) => !!v || "Lozinka je obavezna"],
    };
  },
  methods: {
    redirectToSignUp() {
      this.$router.push("/registracija");
    },
    ...mapActions(["LogIn"]),
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let userForm = {
          userName: this.login.email,
          password: this.login.password,
        };
        await this.LogIn(userForm)
          .then(() => {
            this.loading = false;
            this.$router.push("pocetna");
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data.includes("Password entered incorectly 5 times")) {
              this.errorMessage = "Pogrešno ste uneli lozinku 5 puta. Pokušajte ponovo kasnije.";
            } else if (err.response.data.includes("Password entered incorectly.")) {
              this.errorMessage = "Pogrešna lozinka. Pokušajte ponovo.";
            } else if (err.response.data.includes("Failed to login. No user with username")) {
              this.errorMessage = "Ne postoji korisnik sa unetim korisničkim imenom.";
            } else {
              this.errorMessage = "Došlo je do greške. Pokušajte ponovo kasnije.";
            }
            setTimeout(() => (this.errorMessage = ""), 3000);
          });
      }
    },
  },
  props: {
    value: Boolean,
  },
  mounted() {
    if (this.$route.query.email?.length > 0) {
      this.login.email = this.$route.query.email;
    }
  },
};
</script>

<style lang="scss" scoped>
.logInButton {
  width: 100%;
  padding: 0.8rem;
  color: #fff;
  background-color: #137547;
  border-radius: 4px;
}

.registerButton {
  width: 100%;
  padding: 0.8rem;
  border-radius: 4px;
  border: 1px solid #137547;
  color: #137547;
}

.dialogWrapper {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialogWrapper .dialogWindow h2 {
  margin: 0rem auto 1rem auto;
}

.dialogWindow {
  padding: 2rem 1rem;
  width: 600px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.forgottenPassword {
  color: #137547;
  text-align: center;
  margin-bottom: 2rem;
}
.noAccountQuestion {
  border-top: 2px solid #137547;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
  font-size: 1rem;
  text-align: center;
}

p {
  color: red;
  text-align: center;
}
</style>
